import { Redirect, Route, Switch } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { settings, gitNetwork } from "ionicons/icons";
import NetworkPage from "./pages/NetworkPage/NetworkPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import NetworksPage from "./pages/NetworksPage/NetworksPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { App as CapApp } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { GuardedRoute } from "./components/Routes";

setupIonicReact();

const App: React.FC = () => {
  const { handleRedirectCallback, isAuthenticated } = useAuth0();

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener("appUrlOpen", async ({ url }) => {
      if (
        url.includes("state") &&
        (url.includes("code") || url.includes("error"))
      ) {
        await handleRedirectCallback(url);
      }
      // No-op on Android
      await Browser.close();
    });
  }, [handleRedirectCallback]);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Switch>
              {/*<Route component={LoginPage} exact path="/login"/>*/}
              <Route component={NetworkPage} path="/networks/:id" />
              <Route component={NetworksPage} path="/networks" />
              <Route component={SettingsPage} exact path="/settings" />
              <Route path="*">
                <Redirect to="/networks" />
              </Route>
            </Switch>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton
              disabled={!isAuthenticated}
              tab="networks"
              href="/networks"
            >
              <IonIcon icon={gitNetwork} />
              <IonLabel>Networks</IonLabel>
            </IonTabButton>
            <IonTabButton
              disabled={!isAuthenticated}
              tab="settings"
              href="/settings"
            >
              <IonIcon icon={settings} />
              <IonLabel>Settings</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
