import { useAuth0 } from "@auth0/auth0-react";
import { IonAlert } from "@ionic/react";
import { Networks } from "../../../services";

interface showAlertObject {
  networkId: number;
  show: boolean;
}

interface DeleteNetworkAlertProps {
  showDeleteAlert: showAlertObject;
  setShowDeleteAlert: Function;
  showDeleteAlertDefaultState: showAlertObject;
  onFinish: Function;
}

const DeleteNetworkAlert: React.FC<DeleteNetworkAlertProps> = (props) => {
  const {
    showDeleteAlert,
    setShowDeleteAlert,
    showDeleteAlertDefaultState,
    onFinish,
  } = props;

  const { getAccessTokenSilently } = useAuth0();

  const deleteNetwork = async () => {
    const jwt = await getAccessTokenSilently();
    const networksInstance = new Networks(jwt);
    await networksInstance.deleteNetworkById(showDeleteAlert.networkId);
    setShowDeleteAlert(showDeleteAlertDefaultState);
    onFinish();
  };

  return (
    <IonAlert
      isOpen={showDeleteAlert.show}
      onDidDismiss={() => setShowDeleteAlert(showDeleteAlertDefaultState)}
      header="Are you sure?"
      message="The network will be deleted forever. Are you sure that you want to delete the network?"
      buttons={[
        {
          text: "Cancel",
          cssClass: "primary",
          id: "cancel-button",
          handler: () => setShowDeleteAlert(showDeleteAlertDefaultState),
        },
        {
          text: "Delete",
          cssClass: "danger",
          id: "delete-network-button",
          handler: deleteNetwork,
        },
      ]}
    />
  );
};

export default DeleteNetworkAlert;
