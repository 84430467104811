interface formData {
  [name: string]: string | number | boolean;
}

export function sanitizeFormInput(formData: Object): formData {
  const newFormData: formData = {};
  Object.entries(formData).forEach(([key, value]) => {
    const condition = value !== "" && value !== undefined && value !== null;
    if (condition) {
      newFormData[key] = value;
    }
  });

  return newFormData;
}
