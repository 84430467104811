import { Network, Contact, Relationship } from "./models";
import { apiUrl } from "../../auth.config";
import axios from "axios";

export class Networks {
  private readonly jwt: string;

  constructor(jwt: string) {
    this.jwt = jwt;
  }

  private getDefaultAxiosConfig(
    additionalConfigurations: Object = {},
    additionalHeaders: Object = {}
  ): Object {
    return {
      ...additionalConfigurations,
      headers: {
        Authorization: `Bearer ${this.jwt}`,
        ...additionalHeaders,
      },
    };
  }

  async getOwnNetworks(): Promise<Array<Network>> {
    const { data } = await axios.get(
      `${apiUrl}/networks`,
      this.getDefaultAxiosConfig()
    );
    return data;
  }

  async getWholeNetworkById(
    id: number
  ): Promise<{ contacts: []; relationships: [] }> {
    const { data } = await axios.get(
      `${apiUrl}/contacts?network_id=${id}`,
      this.getDefaultAxiosConfig()
    );
    return data;
  }

  async createNetwork(name: string): Promise<Network> {
    const { data } = await axios.post(
      `${apiUrl}/networks`,
      { name },
      this.getDefaultAxiosConfig()
    );
    return data;
  }

  async updateNetwork(networkId: number, name: string): Promise<Network> {
    const { data } = await axios.put(
      `${apiUrl}/networks/${networkId}`,
      { name },
      this.getDefaultAxiosConfig()
    );
    return data;
  }

  async deleteNetworkById(id: number): Promise<Network> {
    const { data } = await axios.delete(
      `${apiUrl}/networks/${id}`,
      this.getDefaultAxiosConfig()
    );
    return data;
  }

  async createContactInNetwork(
    networkId: number,
    contact: Contact
  ): Promise<Contact> {
    const { data } = await axios.post(
      `${apiUrl}/contacts/${networkId}`,
      contact,
      this.getDefaultAxiosConfig()
    );
    return data;
  }

  async updateContactInNetwork(contact: Contact): Promise<Contact> {
    const { data } = await axios.put(
      `${apiUrl}/contacts/${contact.id}`,
      contact,
      this.getDefaultAxiosConfig()
    );
    return data;
  }

  async deleteContactInNetwork(contactId: number): Promise<Contact> {
    const { data } = await axios.delete(
      `${apiUrl}/contacts/${contactId}`,
      this.getDefaultAxiosConfig()
    );
    return data;
  }

  async createRelationship(
    network_id: number,
    relationship: Relationship
  ): Promise<Relationship> {
    const payload = { ...relationship, network_id };
    const { data } = await axios.post(
      `${apiUrl}/contacts/connect`,
      payload,
      this.getDefaultAxiosConfig()
    );
    return data;
  }

  async deleteRelationship(
    networkId: number,
    relationshipId: number
  ): Promise<Relationship> {
    const { data } = await axios.delete(
      `${apiUrl}/contacts/disconnect`,
      this.getDefaultAxiosConfig({
        data: {
          network_id: networkId,
          relationshipId: relationshipId,
        },
      })
    );

    return data;
  }
}
