import { IonButton } from "@ionic/react";
import { Browser } from "@capacitor/browser";
import { callbackUri } from "../../auth.config";
import { useAuth0 } from "@auth0/auth0-react";

export const LogoutButton: React.FC = () => {
  const { buildLogoutUrl, logout } = useAuth0();

  const handleLogout = async () => {
    await Browser.open({
      url: buildLogoutUrl({ returnTo: callbackUri }),
      windowName: "_self",
    });

    logout();
  };

  return <IonButton onClick={handleLogout}>Log out</IonButton>;
};
