import Graph from "react-graph-vis";
import { useEffect, useState } from "react";
import { Networks } from "../../services";
import { useAuth0 } from "@auth0/auth0-react";
import { getNodeColor, getSourceContactConfiguration } from "./helpers";
import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { add } from "ionicons/icons";

import "./GraphVis.css";
import CreateNetworkComponents from "./CreateNetworkComponents";
import CreateOrUpdateContactAlert from "./CreateOrUpdateContactAlert";
import CreateOrUpdateRelationshipAlert from "./CreateOrUpdateRelationshipAlert";

export const GraphVis = ({ networkID }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [graph, setGraph] = useState({ nodes: [], edges: [] });
  const [contacts, setContacts] = useState([]);
  const [contactIndex, setContactIndex] = useState({});
  const [relationshipIndex, setRelationshipIndex] = useState({});
  const [networkInstance, setNetworkInstance] = useState(null);

  const [showCreateNetworkComponentAlert, setShowCreateNetworkComponentAlert] =
    useState(false);

  const createOrUpdateContactDefaultState = {
    network_id: 0,
    mode: "Create",
    show: false,
    data: {
      name: "",
      gender: "",
      birthday: "",
      note: "",
      contactThresholdInDays: 100,
    },
  };
  const [createOrUpdateContactState, setCreateOrUpdateContactState] = useState(
    createOrUpdateContactDefaultState
  );

  const createOrUpdateRelationshipDefaultState = {
    show: false,
    mode: "Create",
    data: {
      id: 0,
      contactOneId: 0,
      contactTwoId: 0,
      label: "",
    },
  };
  const [createOrUpdateRelationshipState, setCreateOrUpdateRelationshipState] =
    useState(createOrUpdateRelationshipDefaultState);

  useEffect(() => {
    getNetwork();
  }, []);

  // Set source contact to the center (Position of source contact is fixed)
  useEffect(() => {
    if (networkInstance) {
      networkInstance.moveTo({
        position: {
          x: 0,
          y: 0,
        },
      });
    }
  }, [networkInstance]);

  const getNetwork = async () => {
    const jwt = await getAccessTokenSilently();
    const networksService = new Networks(jwt);
    const data = await networksService.getWholeNetworkById(networkID);

    // On click events just returns id. For fast search indexes are created
    const newContactIndex = {};
    const newRelationshipIndex = {};

    const network = {
      nodes: data.contacts.map((contact) => {
        newContactIndex[contact.id] = contact;
        return {
          id: contact.id,
          label: contact.name,
          color: getNodeColor(contact),
          ...getSourceContactConfiguration(contact),
        };
      }),
      edges: data.relationships.map((relationship) => {
        newRelationshipIndex[relationship.id] = relationship;
        return {
          id: relationship.id,
          from: relationship.contactOneId,
          to: relationship.contactTwoId,
          label: relationship.label,
        };
      }),
    };

    setContactIndex(newContactIndex);
    setRelationshipIndex(newRelationshipIndex);
    setContacts(data.contacts);
    setGraph(network);
  };

  const handleContactClick = (e) => {
    // Check if only one node is selected
    if (e.nodes.length !== 1) return false;

    // Extract contactId and fetch contact from the index
    const [contactId] = e.nodes;
    const contact = contactIndex[contactId];

    // Check if contact is not the source
    if (contact.source) return false;

    // Open UpdateContactState
    setCreateOrUpdateContactState({
      ...createOrUpdateContactState,
      mode: "Update",
      show: true,
      data: contact,
    });

    return true;
  };

  const handleRelationshipClick = (e) => {
    // Check if only one edge is selected
    if (e.edges.length !== 1) return;

    // Extract relationshipId and fetch relationship from the index
    const [relationshipId] = e.edges;
    const relationship = relationshipIndex[relationshipId];

    // Open UpdateRelationshipState
    setCreateOrUpdateRelationshipState({
      ...createOrUpdateRelationshipState,
      mode: "Update",
      show: true,
      data: relationship,
    });
  };

  const options = {
    layout: {
      hierarchical: false,
    },
    nodes: {
      shape: "circle",
      heightConstraint: 20,
      widthConstraint: 20,
      font: {
        size: 7,
      },
    },
    edges: {
      color: "#ffffff",
      font: {
        size: 7,
        align: "middle",
      },
      arrows: {
        to: {
          type: "bar",
        },
      },
    },
    height: "100%",
    width: "100%",
    autoResize: true,
    physics: {
      solver: "repulsion",
      repulsion: {
        nodeDistance: 100,
        springLength: 50,
        damping: 0.5,
      },
    },
  };

  return (
    <div className="container">
      <Graph
        graph={graph}
        options={options}
        getNetwork={(network) => {
          setNetworkInstance(network);
        }}
        events={{
          click: (e) => {
            // Unselect the edges and nodes for potential bugs
            networkInstance.unselectAll();

            // Execute handleContactClick(e)
            const contactWasActuallyClickedAndHandled = handleContactClick(e);

            // If was clicked and handled, stop the function and don't proceed to handleRelationshipClick(e)
            // Reason: If clicking on node, also the connected relationship is touched
            if (contactWasActuallyClickedAndHandled) return;

            handleRelationshipClick(e);
          },
        }}
      />
      <CreateNetworkComponents
        show={showCreateNetworkComponentAlert}
        setShow={setShowCreateNetworkComponentAlert}
        onConfirm={(createComponent) => {
          if (createComponent === "Contact") {
            setCreateOrUpdateContactState({
              ...createOrUpdateContactState,
              show: true,
              mode: "Create",
            });
          }
          if (createComponent === "Relationship") {
            setCreateOrUpdateRelationshipState({
              ...createOrUpdateRelationshipState,
              show: true,
              mode: "Create",
            });
          }
        }}
      />
      <CreateOrUpdateContactAlert
        networkID={networkID}
        createOrUpdateContactState={createOrUpdateContactState}
        createOrUpdateContactDefaultState={createOrUpdateContactDefaultState}
        setCreateOrUpdateContactState={setCreateOrUpdateContactState}
        onFinish={getNetwork}
      />
      <CreateOrUpdateRelationshipAlert
        networkID={networkID}
        createOrUpdateRelationshipState={createOrUpdateRelationshipState}
        createOrUpdateRelationshipDefaultState={
          createOrUpdateRelationshipDefaultState
        }
        setCreateOrUpdateRelationshipState={setCreateOrUpdateRelationshipState}
        contactsOfNetwork={contacts}
        onFinish={getNetwork}
      />
      <IonFab className="add-contact-fab">
        <IonFabButton
          onClick={() => setShowCreateNetworkComponentAlert(true)}
          color="primary"
        >
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
    </div>
  );
};
