const nodesColorMap = {
  male: "#1E90FF",
  female: "#EE82EE",
  source: "#FFFFFF",
  unknown: "#708090",
};

export function getNodeColor(contact) {
  let color = nodesColorMap.unknown;

  if (contact.gender in nodesColorMap) {
    color = nodesColorMap[contact.gender];
  }

  return color;
}

export function getSourceContactConfiguration(contact) {
  if (!contact.source) return {};

  return {
    color: nodesColorMap.source,
    x: 0,
    y: 0,
    fixed: {
      x: true,
      y: true,
    },
  };
}
