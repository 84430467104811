import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./NetworkPage.css";
import { GraphVis } from "../../components/GraphVis";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams } from "react-router";

interface NetworkPageParams {
  id: string;
}

const NetworkPage: React.FC = () => {
  const { id } = useParams<NetworkPageParams>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="toolbar">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Back Button</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Networks</IonTitle>
          </IonToolbar>
        </IonHeader>
        <GraphVis networkID={id} />
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(NetworkPage);
