import { useAuth0 } from "@auth0/auth0-react";
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { Networks } from "../../../services";
import { Contact } from "../../../services/networks/models";
import "./CreateOrUpdateContactAlert.css";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { MouseEventHandler, useEffect } from "react";
import { sanitizeFormInput } from "../../../util";

interface Mapper {
  Create: {
    fieldValues: Contact;
    onSubmit: SubmitHandler<FieldValues>;
  };
  Update: {
    fieldValues: Contact;
    onSubmit: SubmitHandler<FieldValues>;
  };
}

interface CreateOrUpdateContactState {
  mode: "Create" | "Update";
  show: boolean;
  data: Contact;
}

interface CreateOrUpdateContactProps {
  networkID: number;
  createOrUpdateContactState: CreateOrUpdateContactState;
  createOrUpdateContactDefaultState: CreateOrUpdateContactState;
  setCreateOrUpdateContactState: Function;
  onFinish?: Function;
}

const CreateOrUpdateContactAlert: React.FC<CreateOrUpdateContactProps> = ({
  networkID,
  createOrUpdateContactState,
  createOrUpdateContactDefaultState,
  setCreateOrUpdateContactState,
  onFinish,
}) => {
  const { mode, show, data } = createOrUpdateContactState;
  const { getAccessTokenSilently } = useAuth0();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const finishProcess = async () => {
    if (typeof onFinish === "function") await onFinish();
    setCreateOrUpdateContactState(createOrUpdateContactDefaultState);
  };

  const createContact: SubmitHandler<FieldValues> = async (data) => {
    const contact = data as Contact;
    const jwt = await getAccessTokenSilently();
    const networksInstance = new Networks(jwt);
    await networksInstance.createContactInNetwork(networkID, contact);
    await finishProcess();
  };

  const updateContact: SubmitHandler<FieldValues> = async (data) => {
    const contact = data as Contact;
    const jwt = await getAccessTokenSilently();
    const networksInstance = new Networks(jwt);
    await networksInstance.updateContactInNetwork(contact);
    await finishProcess();
  };

  const deleteContact: MouseEventHandler = async () => {
    const jwt = await getAccessTokenSilently();
    const networksInstance = new Networks(jwt);
    await networksInstance.deleteContactInNetwork(data.id!);
    await finishProcess();
  };

  const mapper: Mapper = {
    Create: {
      fieldValues: {
        name: "",
        gender: "none",
      },
      onSubmit: createContact,
    },
    Update: {
      fieldValues: {
        id: data.id,
        name: data.name,
        gender: data.gender,
        birthday: data.birthday,
        note: data.note,
        contactThresholdInDays: data.contactThresholdInDays,
      },
      onSubmit: updateContact,
    },
  };

  let birthdayInRightFormat: string | undefined;

  if (mapper[mode].fieldValues.birthday) {
    const birthdayDate = new Date(mapper[mode].fieldValues.birthday as string);
    const year: string = birthdayDate.getFullYear().toString();
    let month: string = (birthdayDate.getUTCMonth() + 1).toString();
    let day: string = birthdayDate.getDate().toString();

    // Format to correct date format
    if (month.length === 1) month = "0" + month;
    if (day.length === 1) day = "0" + day;

    birthdayInRightFormat = `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const fieldValues = mapper[mode].fieldValues;
    setValue("id", fieldValues.id);
    setValue("gender", fieldValues.gender);
    setValue("birthday", birthdayInRightFormat);
    setValue("name", fieldValues.name);
    setValue("note", fieldValues.note);
    setValue("contactThresholdInDays", fieldValues.contactThresholdInDays);
  }, [createOrUpdateContactState]);

  return (
    <>
      <IonModal
        className="modal"
        isOpen={show}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        onDidDismiss={() => {
          reset();
          setCreateOrUpdateContactState(createOrUpdateContactDefaultState);
        }}
      >
        <div className="modal-content-container">
          <h2>{mode} contact</h2>
          <p>
            Fill the form below to {mode.toLowerCase()}{" "}
            {mode === "Update" ? data.name : "a contact"}
          </p>
          <form
            onSubmit={handleSubmit((data) => {
              // "", null and undefined fields are removed
              mapper[mode].onSubmit(sanitizeFormInput(data));
            })}
          >
            <IonInput hidden {...register("id")} />
            <IonItem className="form-item">
              <IonLabel>Name</IonLabel>
              <IonInput
                type="text"
                {...register("name", {
                  required: { value: true, message: "This field is required" },
                  maxLength: { value: 32, message: "Max 30 chars" },
                })}
              />
              {errors.name && (
                <IonText color="danger" className="ion-padding-start">
                  <small>{errors.name.message}</small>
                </IonText>
              )}
            </IonItem>
            <IonItem className="form-item">
              <IonLabel>Gender</IonLabel>
              <IonSelect
                {...register("gender", {
                  required: { value: true, message: "This field is required" },
                })}
              >
                <IonSelectOption value="male">Male</IonSelectOption>
                <IonSelectOption value="female">Female</IonSelectOption>
                <IonSelectOption value="none">None</IonSelectOption>
              </IonSelect>
              {errors.gender && (
                <IonText color="danger" className="ion-padding-start">
                  <small>{errors.gender.message}</small>
                </IonText>
              )}
            </IonItem>
            <IonItem className="form-item">
              <IonLabel>Birthday</IonLabel>
              <IonInput
                type="date"
                {...register("birthday", {
                  value: null,
                })}
              />
              {errors.birthday && (
                <IonText color="danger" className="ion-padding-start">
                  <small>{errors.birthday.message}</small>
                </IonText>
              )}
            </IonItem>
            <IonItem className="form-item">
              <IonLabel>Notes</IonLabel>
              <IonTextarea
                placeholder="A specific note..."
                {...register("note", {
                  max: { value: 1000, message: "Max 1000 chars" },
                })}
              ></IonTextarea>
              {errors.note && (
                <IonText color="danger" className="ion-padding-start">
                  <small>{errors.note.message}</small>
                </IonText>
              )}
            </IonItem>
            <IonItem className="form-item">
              <IonLabel>Reminder to contact in days</IonLabel>
              <IonInput type="number" {...register("contactThresholdInDays")} />
            </IonItem>

            <IonButton type="submit">{mode}</IonButton>
            {mode === "Update" && (
              <IonButton color="danger" onClick={deleteContact}>
                Delete
              </IonButton>
            )}
          </form>
        </div>
      </IonModal>
    </>
  );
};

export default CreateOrUpdateContactAlert;
