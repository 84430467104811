import "./ExploreContainer.css";
import { IonButton } from "@ionic/react";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton, LogoutButton } from "./Buttons";

interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="container">
      <strong>{name}</strong>
      <p>
        Explore{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://ionicframework.com/docs/components"
        >
          UI Components
        </a>
      </p>
      {isAuthenticated ? <LogoutButton /> : <LoginButton />}
    </div>
  );
};

export default ExploreContainer;
