import { isPlatform } from "@ionic/react";

export const appName = "SocialG";
export const domain = process.env.REACT_APP_AUTH0_DOMAIN;
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIEND_ID;
export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
export const apiUrl = process.env.REACT_APP_API_URL;
const appId = "io.socialg.app";

// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
const auth0Domain = domain;
const iosOrAndroid = isPlatform("ios") || isPlatform("android");

export const callbackUri = iosOrAndroid
  ? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
  : process.env.REACT_APP_WEB_URL;
