import { IonAlert } from "@ionic/react";
import { Contact, Relationship } from "../../../services/networks/models";

interface CreateNetworkComponentsProps {
  show: boolean;
  setShow: Function;
  onConfirm: Function;
}

const CreateNetworkComponents: React.FC<CreateNetworkComponentsProps> = ({
  show,
  setShow,
  onConfirm,
}) => {
  return (
    <>
      <IonAlert
        isOpen={show}
        onDidDismiss={() => setShow(false)}
        header="Create network components"
        message="You can add network components like contacts or relationships to you network."
        buttons={[
          {
            text: "Create contact",
            handler: () => {
              onConfirm("Contact");
            },
          },
          {
            text: "Create relationship",
            handler: () => {
              onConfirm("Relationship");
            },
          },
        ]}
      />
    </>
  );
};

export default CreateNetworkComponents;
