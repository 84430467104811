import { useAuth0 } from "@auth0/auth0-react";
import { IonAlert } from "@ionic/react";
import { Networks } from "../../../services";
import { Network } from "../../../services/networks/models";

interface FormDataInterface {
  name: string;
}

export interface CreateOrUpdateAlertState {
  mode: "Create" | "Update";
  show: boolean;
  data: Network;
}

interface CreateOrUpdateNetworkAlertProps {
  createOrUpdateAlertState: CreateOrUpdateAlertState;
  createOrUpdateAlertDefaultState: CreateOrUpdateAlertState;
  setCreateOrUpdateAlertState: Function;
  onFinish: (data: Network) => {};
}

const CreateOrUpdateNetworkAlert: React.FC<CreateOrUpdateNetworkAlertProps> = ({
  createOrUpdateAlertState,
  createOrUpdateAlertDefaultState,
  setCreateOrUpdateAlertState,
  onFinish,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { mode, show, data } = createOrUpdateAlertState;
  const { id: networkId, name: networkName } = data;

  const createNetwork = async (data: FormDataInterface) => {
    const jwt = await getAccessTokenSilently();
    const networksInstance = new Networks(jwt);
    const network = await networksInstance.createNetwork(data.name);
    finishProcess(network);
  };

  const updateNetwork = async (data: FormDataInterface) => {
    const jwt = await getAccessTokenSilently();
    const networksInstance = new Networks(jwt);
    const network = await networksInstance.updateNetwork(networkId, data.name);
    finishProcess(network);
  };

  const finishProcess = (network: Network) => {
    setCreateOrUpdateAlertState(createOrUpdateAlertDefaultState);
    onFinish(network);
  };

  const mapper = {
    Update: {
      inputValue: networkName,
      onSubmit: updateNetwork,
    },
    Create: {
      inputValue: "",
      onSubmit: createNetwork,
    },
  };

  return (
    <IonAlert
      isOpen={show}
      onDidDismiss={() =>
        setCreateOrUpdateAlertState(createOrUpdateAlertDefaultState)
      }
      header={mode}
      inputs={[
        {
          name: "name",
          type: "text",
          placeholder: "My special network...",
          value: mapper[mode].inputValue,
          label: "Network name",
        },
      ]}
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Submit",
          cssClass: "primary",
          handler: (data) => {
            mapper[mode].onSubmit(data);
          },
        },
      ]}
    />
  );
};

export default CreateOrUpdateNetworkAlert;
