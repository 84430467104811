import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonButton,
  IonFab,
  IonFabButton,
} from "@ionic/react";
import { LogoutButton } from "../../components/Buttons";
import { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Networks } from "../../services";
import { Network } from "../../services/networks/models";
import { trash, add, pencil } from "ionicons/icons";

import "./NetworksPage.css";
import { useHistory } from "react-router";
import DeleteNetworkAlert from "./DeleteNetworkAlert";
import CreateOrUpdateNetworkAlert, {
  CreateOrUpdateAlertState,
} from "./CreateOrUpdateNetworkAlert";

const NetworksPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [networks, setNetworks] = useState<Network[]>([]);

  const showDeleteAlertDefaultState = { networkId: 0, show: false };
  const [showDeleteAlert, setShowDeleteAlert] = useState(
    showDeleteAlertDefaultState
  );

  const createOrUpdateAlertDefaultState: CreateOrUpdateAlertState = {
    mode: "Create",
    show: false,
    data: { id: 0, name: "" },
  };
  const [createOrUpdateAlertState, setCreateOrUpdateAlertState] =
    useState<CreateOrUpdateAlertState>(createOrUpdateAlertDefaultState);

  useEffect(() => {
    getNetworks();
  }, []);

  const getNetworks = async () => {
    const jwt = await getAccessTokenSilently();
    const networksInstance = new Networks(jwt);
    const data = await networksInstance.getOwnNetworks();
    setNetworks(data);
  };

  const handleNetworkCreation = () => {
    setCreateOrUpdateAlertState({
      ...createOrUpdateAlertDefaultState,
      show: true,
    });
  };

  const handleNetworkUpdate = (e: React.MouseEvent, network: Network) => {
    e.stopPropagation();
    setCreateOrUpdateAlertState({
      ...createOrUpdateAlertDefaultState,
      mode: "Update",
      show: true,
      data: { ...network },
    });
  };

  const handleNetworkDeletion = async (
    e: React.MouseEvent,
    networkId: number
  ) => {
    e.stopPropagation();
    setShowDeleteAlert({ networkId, show: true });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Networks</IonTitle>
          <LogoutButton />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="content">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Networks</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="networks-list-container">
          <IonList>
            {networks.map((network) => (
              <IonItem
                key={network.id}
                onClick={() => history.push("/networks/" + network.id)}
                button
                className="networks-list-item"
              >
                <IonLabel>{network.name}</IonLabel>
                <IonButton
                  color="none"
                  onClick={(e) => handleNetworkUpdate(e, network)}
                >
                  <IonIcon icon={pencil} color="primary" />
                </IonButton>
                <IonButton
                  color="none"
                  onClick={(e) => handleNetworkDeletion(e, network.id)}
                >
                  <IonIcon icon={trash} color="danger" />
                </IonButton>
              </IonItem>
            ))}
          </IonList>
          <IonFab className="add-network-fab-button">
            <IonFabButton onClick={handleNetworkCreation} color="primary">
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </div>
        <CreateOrUpdateNetworkAlert
          createOrUpdateAlertState={createOrUpdateAlertState}
          setCreateOrUpdateAlertState={setCreateOrUpdateAlertState}
          createOrUpdateAlertDefaultState={createOrUpdateAlertDefaultState}
          onFinish={getNetworks}
        />
        <DeleteNetworkAlert
          showDeleteAlert={showDeleteAlert}
          setShowDeleteAlert={setShowDeleteAlert}
          showDeleteAlertDefaultState={showDeleteAlertDefaultState}
          onFinish={getNetworks}
        />
      </IonContent>
    </IonPage>
  );
};

export default withAuthenticationRequired(NetworksPage);
