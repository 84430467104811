import { useAuth0 } from "@auth0/auth0-react";
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { FieldValues, set, SubmitHandler, useForm } from "react-hook-form";
import { Networks } from "../../../services";
import { Contact, Relationship } from "../../../services/networks/models";
import "./CreateOrUpdateRelationshipAlert.css";
import { MouseEventHandler, useEffect } from "react";

interface Mapper {
  Create: {
    fieldValues: Relationship;
    onSubmit: SubmitHandler<FieldValues>;
  };
  Update: {
    fieldValues: Relationship;
    onSubmit: SubmitHandler<FieldValues>;
  };
}

interface CreateOrUpdateRelationshipState {
  mode: "Create" | "Update";
  show: boolean;
  data: Relationship;
}

interface CreateOrUpdateRelationshipAlertProps {
  networkID: number;
  createOrUpdateRelationshipState: CreateOrUpdateRelationshipState;
  createOrUpdateRelationshipDefaultState: CreateOrUpdateRelationshipState;
  setCreateOrUpdateRelationshipState: Function;
  contactsOfNetwork: Contact[];
  onFinish?: Function;
}

const CreateOrUpdateRelationshipAlert: React.FC<
  CreateOrUpdateRelationshipAlertProps
> = ({
  networkID,
  createOrUpdateRelationshipState,
  createOrUpdateRelationshipDefaultState,
  setCreateOrUpdateRelationshipState,
  contactsOfNetwork,
  onFinish,
}) => {
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const { getAccessTokenSilently } = useAuth0();

  const { data, mode, show } = createOrUpdateRelationshipState;

  const finishProcess = async () => {
    setCreateOrUpdateRelationshipState(createOrUpdateRelationshipDefaultState);
    if (typeof onFinish === "function") await onFinish();
  };

  const createRelationship: SubmitHandler<FieldValues> = async (data) => {
    const relationship = data as Relationship;
    const jwt = await getAccessTokenSilently();
    const networksInstance = new Networks(jwt);
    await networksInstance.createRelationship(networkID, relationship);
    await finishProcess();
  };

  const updateRelationship: SubmitHandler<FieldValues> = async (data) => {
    alert(
      "There is no relationship update logic. Nor at the api, nor in the frontend services"
    );
    /*const relationship = data as Relationship;
      const jwt = await getAccessTokenSilently();
      const networksInstance = new Networks(jwt);
      await finishProcess(); */
  };

  const deleteRelationship: MouseEventHandler = async () => {
    const jwt = await getAccessTokenSilently();
    const networksInstance = new Networks(jwt);
    await networksInstance.deleteRelationship(networkID, data.id);
    await finishProcess();
  };

  const mapper: Mapper = {
    Create: {
      fieldValues: {
        id: 0,
        contactOneId: null!,
        contactTwoId: null!,
        label: "",
      },
      onSubmit: createRelationship,
    },
    Update: {
      fieldValues: {
        id: data.id,
        contactOneId: data.contactOneId,
        contactTwoId: data.contactTwoId,
        label: data.label,
      },
      onSubmit: updateRelationship,
    },
  };

  useEffect(() => {
    const fieldValues = mapper[mode].fieldValues;
    setValue("id", fieldValues.id);
    setValue("contactOneId", fieldValues.contactOneId);
    setValue("contactTwoId", fieldValues.contactTwoId);
    setValue("label", fieldValues.label);
  }, [createOrUpdateRelationshipState]);

  return (
    <IonModal
      className="modal"
      isOpen={show}
      initialBreakpoint={1}
      breakpoints={[0, 0.5, 1]}
      onDidDismiss={() => {
        reset();
        setCreateOrUpdateRelationshipState(
          createOrUpdateRelationshipDefaultState
        );
      }}
    >
      <div className="modal-content-container">
        <h2>{mode} relationship</h2>
        <p>
          Fill the form below to {mode.toLowerCase()}{" "}
          {mode === "Update"
            ? `Relationship '${data.label}'`
            : "a relationship between two contacts"}
        </p>
        <form onSubmit={handleSubmit(mapper[mode].onSubmit)}>
          <IonInput
            hidden
            {...register("id", { value: mapper[mode].fieldValues.id })}
          />
          <IonItem className="form-item">
            <IonLabel>Relationship type</IonLabel>
            <IonInput
              type="text"
              {...register("label", {
                required: { value: true, message: "This field is required" },
                max: { value: 20, message: "Max 20 chars" },
              })}
            />
            {errors.label && (
              <IonText color="danger" className="ion-padding-start">
                <small>{errors.label.message}</small>
              </IonText>
            )}
          </IonItem>
          <IonItem className="form-item">
            <IonLabel>Contact One</IonLabel>
            <IonSelect
              {...register("contactOneId", {
                required: { value: true, message: "This field is required" },
              })}
            >
              {contactsOfNetwork.map((contact) => (
                <IonSelectOption key={contact.id} value={contact.id}>
                  {contact.name}
                </IonSelectOption>
              ))}
            </IonSelect>
            {errors.contactOneId && (
              <IonText color="danger" className="ion-padding-start">
                <small>{errors.contactOneId.message}</small>
              </IonText>
            )}
          </IonItem>
          <IonItem className="form-item">
            <IonLabel>Contact Two</IonLabel>
            <IonSelect
              {...register("contactTwoId", {
                required: { value: true, message: "This field is required" },
              })}
            >
              {contactsOfNetwork.map((contact) => (
                <IonSelectOption key={contact.id} value={contact.id}>
                  {contact.name}
                </IonSelectOption>
              ))}
            </IonSelect>
            {errors.contactTwoId && (
              <IonText color="danger" className="ion-padding-start">
                <small>{errors.contactTwoId.message}</small>
              </IonText>
            )}
          </IonItem>
          <IonButton type="submit">{mode}</IonButton>
          {mode === "Update" && (
            <IonButton color="danger" onClick={deleteRelationship}>
              Delete
            </IonButton>
          )}
        </form>
      </div>
    </IonModal>
  );
};

export default CreateOrUpdateRelationshipAlert;
